
import { computed, defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { LessonCard } from '.'
import { ContextMenu } from '~/shared/ui/base'
import { PermissionsEnum } from '~/shared/enums'
import { usePermissions } from '~/composables'
import type { LessonModel } from '~/entities/lesson/model'
import type { ContextMenuItem } from '~/interfaces/context-menu'

export default defineComponent({
  name: 'LessonCardWithMenu',
  components: { SvoiIcon, LessonCard, ContextMenu },
  props: {
    lesson: {
      type: Object as PropType<LessonModel>,
      required: true,
    },
    link: {
      type: String,
      default: '',
    },
  },
  emits: ['on-click-delete'],
  setup() {
    const { hasPermission } = usePermissions()

    const showContextMenu = ref(false)
    const contextMenuActions = computed<Array<ContextMenuItem & { active: boolean }>>(() => [
      {
        name: 'Убрать назначение',
        action: 'on-click-delete',
        active: hasPermission(PermissionsEnum.withdrawLearningProgramLessonFromClass),
      },
    ])
    const activeContextMenuActions = computed(() => contextMenuActions.value.filter((i) => i.active))

    return { showContextMenu, activeContextMenuActions }
  },
})
