
import { computed, defineComponent, PropType, ref, useContext } from '@nuxtjs/composition-api'
import { SvoiButton } from '@svoi-ui/components/button'
import { SvoiInput } from '@svoi-ui/components/input'
import { SvoiSelect } from '@svoi-ui/components/select'
import type { LessonScheduledFilters } from '~/entities/lesson/interface'
import { LessonScheduledFilterTypeEnum } from '~/entities/lesson/enums'
import type { SubjectModel } from '~/entities/subject/model'
import type { LearningBlockModel } from '~/entities/learning-block/model'

export default defineComponent({
  name: 'ScheduledLessonsFilters',
  components: { SvoiButton, SvoiInput, SvoiSelect },
  props: {
    initialFilters: {
      type: Object as PropType<LessonScheduledFilters>,
      default: () => {},
    },
    subjects: {
      type: Array as PropType<Array<SubjectModel>>,
      default: () => [],
    },
  },
  emits: ['on-change'],
  setup(props, { emit }) {
    const ctx = useContext()

    const statuses = [
      {
        id: undefined,
        title: 'Все',
      },
      {
        id: LessonScheduledFilterTypeEnum.active,
        title: 'Активные',
      },
      {
        id: LessonScheduledFilterTypeEnum.finished,
        title: 'Пройденные',
      },
    ]
    const classes = computed(() => {
      return [
        {
          id: undefined,
          name: 'Все',
        },
        ...(ctx.$tstore.user.authorizedUser?.classes ?? []),
      ]
    })
    const filters = ref<LessonScheduledFilters>({
      type: props.initialFilters?.type ?? undefined,
      search: props.initialFilters?.search ?? '',
      classId: props.initialFilters?.classId ?? undefined,
      subjectId: props.initialFilters?.subjectId ?? undefined,
      blockId: props.initialFilters?.blockId ?? undefined,
    })
    const selectedSubject = computed({
      get: () => props.subjects?.find((s) => s.id === filters.value.subjectId) ?? null,
      set: (value: SubjectModel | null) => {
        filters.value.subjectId = value ? value.id : undefined
        filters.value.blockId = undefined
        emit('on-change', filters.value)
      },
    })
    const selectedBlock = computed({
      get: () => selectedSubject.value?.blocks?.find((b) => b.id === filters.value.blockId) ?? null,
      set: (value: LearningBlockModel | null) => {
        filters.value.blockId = value ? value.id : undefined
        emit('on-change', filters.value)
      },
    })

    const changeFilterType = (value: LessonScheduledFilters['type']) => {
      filters.value.type = value
      emit('on-change', filters.value)
    }

    const changeFilterClass = (value: string | undefined) => {
      filters.value.classId = value !== undefined ? [value] : undefined
      emit('on-change', filters.value)
    }

    const changeFilterSearch = () => {
      emit('on-change', filters.value)
    }

    const checkClass = (classId: string | undefined) =>
      filters.value.classId === undefined ? classId === undefined : filters.value.classId[0] === classId

    return {
      statuses,
      filters,
      classes,
      selectedSubject,
      selectedBlock,
      changeFilterType,
      changeFilterSearch,
      changeFilterClass,
      checkClass,
    }
  },
})
