
import { computed, defineComponent, PropType, ref, useRouter } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { SvoiLabel } from '@svoi-ui/components/label'
import { SvoiTooltipped } from '@svoi-ui/components/tooltip'
import { LessonStatus } from '.'
import { ContextMenu } from '~/shared/ui/base'
import type { LessonModel } from '~/entities/lesson/model'
import { LearningStatusesEnum, PermissionsEnum } from '~/shared/enums'
import type { ContextMenuItem } from '~/interfaces/context-menu'
import { usePermissions } from '~/composables'

export default defineComponent({
  name: 'LessonCardShort',
  components: { LessonStatus, SvoiIcon, SvoiLabel, ContextMenu, SvoiTooltipped },
  props: {
    type: {
      type: String as PropType<'base' | 'program'>,
      default: 'base',
    },
    lesson: {
      type: Object as PropType<LessonModel>,
      required: true,
    },
    asLink: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['on-change-status'],
  setup(props) {
    const router = useRouter()
    const { hasPermission } = usePermissions()
    const showContextMenu = ref(false)

    const permissions = {
      edit: hasPermission(
        props.type === 'base'
          ? PermissionsEnum.updateLearningKnowledgeBaseLesson
          : PermissionsEnum.updateLearningProgramLesson
      ),
    }

    const number = computed(() => `${props.type === 'base' ? 'Запись' : 'Урок'} № ${props.lesson.number}`)
    const link = computed(
      () => `/account/${props.type === 'base' ? 'knowledge-base' : 'program'}/lesson/view/${props.lesson.id}`
    )
    const author = computed(() => {
      const profile = props.lesson.author.profile
      return `${profile.surName} ${profile.name} ${profile.middleName}`.trim()
    })
    const contextMenuActions = computed<Array<ContextMenuItem & { active: boolean }>>(() => [
      {
        name: 'Редактировать',
        action: 'on-click-edit',
        active: permissions.edit,
      },
      {
        name: 'Опубликовать',
        action: 'on-change-status-active',
        active: permissions.edit && props.lesson.status !== LearningStatusesEnum.active,
      },
      {
        name: 'Черновик',
        action: 'on-change-status-draft',
        active: permissions.edit && props.lesson.status !== LearningStatusesEnum.draft,
      },
      {
        name: 'Удалить',
        action: 'on-change-status-archive',
        active: permissions.edit && props.lesson.status !== LearningStatusesEnum.archive,
      },
    ])

    const activeContextMenuActions = computed(() => contextMenuActions.value.filter((i) => i.active))

    const onClickEditLesson = () => {
      router.push(`/account/${props.type === 'base' ? 'knowledge-base' : 'program'}/lesson/edit/${props.lesson.id}`)
    }

    return {
      showContextMenu,
      link,
      author,
      activeContextMenuActions,
      number,
      LearningStatusesEnum,
      onClickEditLesson,
    }
  },
})
