
import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { SvoiLabel } from '@svoi-ui/components/label'
import { ThemesEnum as LabelThemesEnum } from '@svoi-ui/interfaces/label'
import { LearningStatusesEnum } from '~/shared/enums'
import { LearningStatus } from '~/shared/interface'

export default defineComponent({
  components: { SvoiLabel },

  props: {
    status: {
      type: String as PropType<LearningStatus>,
      required: true,
    },
  },

  setup(props) {
    const labelStatus = computed(() => {
      const data = {
        name: '',
        icon: '',
        theme: LabelThemesEnum.qiwiLight,
      }

      switch (props.status) {
        case LearningStatusesEnum.active: {
          data.name = 'Подтвержден'
          data.icon = 'support/medal'
          break
        }
        case LearningStatusesEnum.review: {
          data.name = 'На рассмотрении'
          data.icon = 'time/clock'
          data.theme = LabelThemesEnum.banana
          break
        }
        case LearningStatusesEnum.draft: {
          data.name = 'Черновик'
          data.icon = 'essential/warning-2'
          data.theme = LabelThemesEnum.chilli
          break
        }
        case LearningStatusesEnum.archive: {
          data.name = 'В архиве'
          data.icon = 'essential/warning-2'
          data.theme = LabelThemesEnum.chilli
        }
      }

      return data
    })

    return { labelStatus }
  },
})
