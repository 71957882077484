
import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { SvoiLabel } from '@svoi-ui/components/label'
import { ThemesEnum } from '@svoi-ui/interfaces/label'
import { ContextMenu } from '~/shared/ui/base'
import { diffDays, toDateFormat } from '~/shared/utils/date'
import { declOfNum, previewUserFIO } from '~/shared/utils/helpers'
import type { LessonModel } from '~/entities/lesson/model'

export default defineComponent({
  name: 'LessonCard',
  components: { SvoiIcon, SvoiLabel, ContextMenu },
  props: {
    lesson: {
      type: Object as PropType<LessonModel>,
      required: true,
    },
    link: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const previewSrc = computed(() => {
      const preview = props.lesson.preview
      return preview?.path ?? require('~/assets/images/lesson/lesson-no-photo.svg')
    })
    const author = computed(() => {
      const author = props.lesson.author.profile
      return previewUserFIO(author.surName, author.name, author.middleName)
    })
    const labelDateProps = computed(() => {
      if (!props.lesson.dateEnd) {
        return undefined
      }

      const diff = diffDays(props.lesson.dateEnd, new Date())
      const name =
        diff < 0
          ? `Завершено ${toDateFormat(props.lesson.dateEnd)}`
          : diff < 1
          ? 'Заканчивается сегодня'
          : diff < 3
          ? `Осталось ${diff + 1} ${declOfNum(diff + 1, ['день', 'дня', 'дней'])}`
          : `до ${toDateFormat(props.lesson.dateEnd)}`
      const theme = diff < 0 ? ThemesEnum.potato : diff < 3 ? ThemesEnum.chilli : ThemesEnum.qiwi

      return { theme, name }
    })

    return { previewSrc, author, labelDateProps }
  },
})
