
import { computed, defineComponent, PropType, ref, useContext, watchEffect } from '@nuxtjs/composition-api'
import Draggable from 'vuedraggable'
import type { LessonModel } from '~/entities/lesson/model'

export default defineComponent({
  components: { Draggable },
  props: {
    lessons: {
      type: Array as PropType<Array<LessonModel>>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const ctx = useContext()

    const originList = ref<Array<LessonModel>>([])

    const draggableOptions = computed(() => ({
      handle: '.draggable-handle',
      setData: modifyDragItem,
    }))

    const modifyDragItem = (dataTransfer: DataTransfer) => {
      dataTransfer.setDragImage(document.createElement('div'), 0, 0)
    }

    const endDrag = () => {
      ctx.$services.lessons.editLessonOrders(originList.value.map((i) => i.id))
    }

    watchEffect(() => (originList.value = [...props.lessons]))

    return {
      originList,
      endDrag,
      draggableOptions,
    }
  },
})
